import React from "react";
// import { useAuth } from "../context/AuthProvider";

export default function Admin(profiles, setProfiles) {
  // const { user } = useAuth();
  return (
    <>
      {/* <div>You are logged in and your email address is {user.email}</div> */}
      {/* <AccessProfiles /> */}
      <br></br>
      {/* <AccessTokenHook /> */}
      {/* <Absences /> */}
    </>
  );
}
